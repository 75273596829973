import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';
import useAppContext from '../../../../hooks/context/useAppContext';

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: 'black',
  },
}));

const TopBar = () => {
  const classes = useStyles({});
  const { breadCrumbs, backNavigation } = useAppContext();
  const history = useHistory();

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            backNavigation ? history.push(backNavigation) : history.goBack();
          }}>
          <ArrowBackIcon />
        </IconButton>
        {breadCrumbs || ''}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {};

export default TopBar;
