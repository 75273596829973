import React from 'react';
import Divider from '@material-ui/core/Divider';
import styled from 'styled-components';
import userMenuLinks from './userMenuLinks';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';
import useAppContext from '../../../hooks/context/useAppContext';
import { useAuthentication } from '../../../hooks/api/useAuthentication';
import { useIntl } from 'react-intl';

const UserMenuHeader = styled.div`
  width: 300px;
  height: 65px;
  display: flex;
  flex-direction: row;

  span {
    color: #2d2d2d;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  span:first-child {
    flex-shrink: 0;
    flex-grow: 0;
    width: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  span:last-child {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
`;

const UserMenuList = styled.div`
  margin: 5px 0;
  a {
    text-decoration: none;
  }
`;

const UserMenuContent = ({ handleClose = () => {} }) => {
  const { getUser } = useAppContext();
  const { logout } = useAuthentication();
  const intl = useIntl();
  const user = getUser();
  const role = user.role;

  const activeLinks = userMenuLinks[role];

  return (
    <>
      <UserMenuHeader>
        <span>{user?.userAvatar && <img src={user?.userAvatar} style={{ width: '30px' }} alt={'User Avatar'} />}</span>
        <span>
          {user.firstname} {user.surname}
        </span>
      </UserMenuHeader>
      <Divider />
      <UserMenuList>
        <MenuList id="menu-list-grow">
          {activeLinks?.map((link, index) => (
            <MenuItem button key={index} component={NavLink} to={link.path} exact={link.exact} onClick={handleClose}>
              <ListItemText primary={intl.formatMessage({ id: link.label })} />
            </MenuItem>
          ))}
          <MenuItem
            button
            onClick={() => {
              logout();
            }}>
            <ListItemText primary={intl.formatMessage({ id: 'app.userMenu.logout' })} />
          </MenuItem>
        </MenuList>
      </UserMenuList>
    </>
  );
};

UserMenuContent.propTypes = {
  handleClose: PropTypes.func,
};

export default UserMenuContent;
