import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { createReducer } from '../reducer';


export default (initialState = {}, handlers = {}) => {
  const contextStore = createContext(initialState);
  const { Provider } = contextStore;

  const ContextProvider = ({ children }) => {
    const reducer = createReducer(initialState, handlers);
    const [state, dispatch] = useReducer(reducer, initialState);
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
  };

  ContextProvider.propTypes = {
    children: PropTypes.node,
  };

  return [
    contextStore,
    ContextProvider,
  ];
};
