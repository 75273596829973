import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Row = styled.div`
  margin-bottom: 20px;
  @media all and (min-width: ${(props) => props.theme.sizes.medium}) {
    display: flex;
    flex-direction: row;
    & > div {
      margin: 0 10px;
    }
    & > div:first-child {
      margin-left: 0;
    }
    & > div:last-child {
      margin-right: 0;
    }
  }
`;

const CardRow = (props) => {
  return <Row>{props.children}</Row>;
};

CardRow.propTypes = {
  children: PropTypes.node,
};

export default CardRow;
