import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { supportedLanguages } from '../../../localizations/index';
import FlagIconFactory from 'react-flag-icon-css';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
  },
}));

const LanguageList = styled.div`
  margin: 5px 0;
  padding: 0 10px;
  a {
    text-decoration: none;
  }
`;

const LanguageMenuContent = ({ changeLanguage }) => {
  const FlagIcon = new FlagIconFactory(React, { useCssModules: false });
  const classes = useStyle();

  return (
    <LanguageList>
      <MenuList id="menu-list-grow">
        {!!supportedLanguages.length &&
          supportedLanguages.map((supportedLanguage) => (
            <MenuItem
              className={classes.listItem}
              key={supportedLanguage.languageCode}
              button
              onClick={() => {
                changeLanguage(supportedLanguage);
              }}>
              <FlagIcon code={supportedLanguage.flagCode} /> <div>{supportedLanguage.title}</div>
            </MenuItem>
          ))}
      </MenuList>
    </LanguageList>
  );
};

LanguageMenuContent.propTypes = {
  handleClose: PropTypes.func,
  changeLanguage: PropTypes.func,
};

export default LanguageMenuContent;
