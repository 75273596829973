import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const StyledHeader = styled.div`
  text-transform: uppercase;
  margin-top: 36px;
  margin-bottom: 24px;
`;

const SectionHeader = ({ children }) => (
  <Typography variant="h5">
    <StyledHeader>{children}</StyledHeader>
  </Typography>
);
export default SectionHeader;
