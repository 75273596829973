const normalize = (value, amount) => {
  let result = value + Math.round(amount);
  if (result > 255) {
    result = 255;
  } else if (result < 0) {
    result = 0;
  }
  return result;
};

export default (color, amount) => {
  if (amount === 0) {
    return color;
  }
  let usePound = false;
  if (color[0] === '#') {
    color = color.slice(1);
    usePound = true;
  }
  const value = parseInt(color, 16);
  let r = normalize(value >> 16, amount);
  let g = normalize(value & 0x0000FF, amount);
  let b = normalize((value >> 8) & 0x00FF, amount);
  [r, g, b] = [r, g, b].map((item) => item <= 15 ? `0${item.toString(16)}` : item.toString(16));
  return (usePound ? '#': '') + r + b + g;
};
