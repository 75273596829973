import useAppContext from '../context/useAppContext';
import { useEffect } from 'react';


const useBreadCrumbs = (breadCrumbs) => {
  const { setBreadCrumbs } = useAppContext();

  useEffect(() => {
    setBreadCrumbs(breadCrumbs);
    return () => {
      setBreadCrumbs('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};


export default useBreadCrumbs;
