import { useCallback } from 'react';
import useApiHook from '../../../../hooks/api/useApiHook';

export const useAuthorize= () => {
  const { get, data, error, loading } = useApiHook(true);

  const authorize = useCallback(async () => {
    await get('/users/authorize');
  }, [get]);

  return { authorize, data, error, loading };
};
