import React from 'react';
import { FormattedMessage } from 'react-intl';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import useBreadCrumbs from '../../hooks/breadCrumbs/useBreadCrumbs';
import BestAskPrices from './components/BestAskPrices/BestAskPrices';

const PublicAskPriceELEPage = () => {
  useBreadCrumbs(<FormattedMessage id={'app.publicaskprice.ele'} />);
  return (
    <>
      <SectionHeader>
        <FormattedMessage id={'app.publicaskprice.ele'} />
      </SectionHeader>
      <BestAskPrices commodity={'ELE'} />
    </>
  );
};

export default PublicAskPriceELEPage;
