import React, { useState } from 'react';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/NotificationsNoneOutlined';
import NotificationMenuContent from '../../../MenuContent/NotificationMenuContent';
import PopoverMenu from '../../../PopoverMenu/PopoverMenu';
import useAppContext from '../../../../hooks/context/useAppContext';
import userRoles from '../../../../constants/userRoles';

const allowedForRoles = [userRoles.CUSTOMER, userRoles.CUSTOMER_VIEWER, userRoles.CUSTOMER_MULTIPLE_COTRACTS, userRoles.CUSTOMER_VIEWER_MULTIPLE_COTRACTS];

const NotificationsMenu = () => {
  const { notificationsCount, getUser } = useAppContext();
  const user = getUser();
  const role = user.role;

  const [display] = useState(allowedForRoles.indexOf(role) !== -1);

  return (
    display && (
      <PopoverMenu
        icon={
          notificationsCount > 0 ? (
            <Badge color="secondary" variant="dot" invisible={false}>
              <NotificationsIcon />
            </Badge>
          ) : (
            <NotificationsIcon />
          )
        }>
        <NotificationMenuContent />
      </PopoverMenu>
    )
  );
};

NotificationsMenu.propTypes = {};

export default NotificationsMenu;
