import useApiHook from '../../../hooks/api/useApiHook';
import { useCallback } from 'react';

export const useGetNotifications = () => {
  const { get, data, error, loading } = useApiHook(true);
  const getNotifications = useCallback(async () => {
    const url = `/notifications`;
    await get(url);
  }, [get]);

  return { getNotifications, data, error, loading };
};


export const useDeleteNotifications = () => {
  const { deleted, data, error, loading } = useApiHook(true);
  const deleteNotifications = useCallback(async (id) => {
    const url = `/notifications/${id}`;
    await deleted(url);
  }, [deleted]);

  return { deleteNotifications, data, error, loading };
};

export const getUrl = (data) => {
  switch (data?.notificationType?.id) {
    case 1:
    case 3:
    case 5:
      return '/';
    case 4:
      return `/contract/${data.idContract}`;
    case 2:
      return data.link ? data.link : '/';
    default:
      return '/';
  }
};
