import React from 'react';
import ElectricityBuy from '../components/Icons/ElectricityBuy';
import ElectricitySale from '../components/Icons/ElectricitySale';
import GasThe from '../components/Icons/GasThe';
import GasCzvtp from '../components/Icons/GasCzvtp';

const commodityType = {
  ELE: 'ELE',
  GAS_THE: 'GAS_THE',
  GAS_CZVTP: 'GAS_CZVTP',
};

export const commodities = {
  ELE: {
    key: 'ELE',
    icon: <ElectricityBuy />,
    unit: 'MWh',
    title: 'app.products.ele',
    commodityType: commodityType.ELE,
  },
  FIX: {
    key: 'FIX',
    icon: <ElectricitySale />,
    unit: 'MWh',
    title: 'app.products.fix',
    commodityType: commodityType.ELE,
  },
  GAS_THE: {
    key: 'GAS_THE',
    icon: <GasThe />,
    unit: 'MWh',
    title: 'app.products.gas.the',
    commodityType: commodityType.GAS_THE,
  },
  GAS_CZVTP: {
    key: 'GAS_CZVTP',
    icon: <GasCzvtp />,
    unit: 'MWh',
    title: 'app.products.gas.czvtp',
    commodityType: commodityType.GAS_CZVTP,
  },
};
