import React from 'react';
import PropTypes from 'prop-types';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { useIntl } from 'react-intl';
import CanvasJSChart from '../../assets/CanvasJSChart';
import {
  GRAY1,
  GRAY3,
  UCLA_GOLD,
} from '../../constants/colors';
import PriceDevelopmentChartTooltip from './PriceDevelopmentChartTooltip';
import { renderToStringWithProviders } from '../../utils/render';
import { commodities } from '../../constants/commodities';

const PriceDevelopmentChart = ({
  axisYTitle,
  axisY2Title,
  chartData,
  tooltipDisabled,
  commodity,
  currency,
}) => {
  const intl = useIntl();
  const options = {
    animationEnabled: true,
    axisY: {
      gridColor: GRAY3,
      includeZero: false,
      labelFontColor: GRAY1,
      labelFontSize: 12,
      lineColor: 'transparent',
      tickColor: 'transparent',
      title: axisYTitle,
      titleFontColor: GRAY1,
      titleFontSize: 12,
    },
    axisY2: {
      gridColor: 'transparent',
      includeZero: false,
      labelFontColor: UCLA_GOLD,
      labelFontSize: 12,
      lineColor: 'transparent',
      tickColor: 'transparent',
      title: axisY2Title,
      titleFontColor: UCLA_GOLD,
      titleFontSize: 12,
    },
    axisX: {
      labelFontColor: GRAY1,
      labelFormatter: (e) => {
        return e.label && format(parseISO(e.label), intl.messages['app.dateFormat']);
      },
      lineColor: GRAY3,
      tickColor: 'transparent',
      tickLength: 15,
    },
    data: chartData,
    toolTip: {
      shared: false,
      enabled: !tooltipDisabled,
      borderThickness: 0,
      cornerRadius: 4,
      content: (graphData) => graphData.entries && renderToStringWithProviders(
        <PriceDevelopmentChartTooltip
          entries={graphData.entries}
          commodity={commodity}
          currency={currency}
        />,
      ),
    },
  };

  return (
    <CanvasJSChart options={options} />
  );
};

PriceDevelopmentChart.propTypes = {
  axisYTitle: PropTypes.string,
  axisY2Title: PropTypes.string,
  chartData: PropTypes.array,
  tooltipDisabled: PropTypes.bool,
  commodity: PropTypes.oneOf(Object.keys(commodities)).isRequired,
  currency: PropTypes.string,
};

export default PriceDevelopmentChart;
