import React from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import mainMenuLinks from './mainMenuLinks';
import useAppContext from '../../../hooks/context/useAppContext';

const MainMenuHeader = styled.div`
  height: 65px;
  display: flex;
  flex-direction: row;
  width: 300px;

  span {
    color: #2d2d2d;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  span:first-child {
    flex-shrink: 0;
    flex-grow: 0;
    width: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  span:last-child {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
`;

const MainMenuList = styled.div`
  margin: 5px 0;
  a {
    text-decoration: none;
  }
`;

const MainMenuContent = () => {
  const { getUser } = useAppContext();
  const user = getUser();
  const role = user.role;

  const activeLinks = mainMenuLinks[role];

  return (
    <div>
      <MainMenuHeader>
        <span>{user.organizationLogo && <img src={user.organizationLogo} style={{ width: '30px' }} alt={'Company logo'} />}</span>
        <span>{user.organization}</span>
      </MainMenuHeader>
      <Divider />
      <MainMenuList>
        <List>
          {activeLinks?.map((link, index) => (
            <ListItem button key={index} component={NavLink} to={link.path} exact={link.exact} onClick={link.action()}>
              <ListItemText primary={link.label} />
            </ListItem>
          ))}
        </List>
      </MainMenuList>
    </div>
  );
};

MainMenuContent.propTypes = {};

export default MainMenuContent;
