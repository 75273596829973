import React, { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import MainMenuContent from '../../../MenuContent/MainMenuContent';
import IconButton from '@material-ui/core/IconButton';

const MainMenu = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const toggleDrawer = (open) => {
    setMenuOpened(open);
  };
  return (
    <>
      <IconButton edge="start" color="inherit" onClick={() => toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer open={menuOpened} onClose={() => toggleDrawer(false)}>
        <MainMenuContent onClose={() => toggleDrawer(false)} />
      </Drawer>
    </>
  );
};

export default MainMenu;
