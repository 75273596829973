import { provideTheme } from '../components/Layout/Layout';
import { provideIntl } from '../App';
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom';

export const renderToStringWithProviders = (component) => {
  return ReactDOMServer.renderToString(provideIntl(provideTheme(component)));
};

export const renderIntoIdWithProviders = (component, id) => {
  ReactDOM.render(
    provideTheme(provideIntl(component)),
    document.getElementById(id));
};
