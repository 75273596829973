export const MonthIndexes = {
  JANUARY: 0,
  FEBRUARY: 1,
  MARCH: 2,
  APRIL: 3,
  MAY: 4,
  JUNE: 5,
  JULY: 6,
  AUGUST: 7,
  SEPTEMBER: 8,
  OCTOBER: 9,
  NOVEMBER: 10,
  DECEMBER: 11,
};

export const QuarterIndexes = {
  Q1: 0,
  Q2: 1,
  Q3: 2,
  Q4: 3,
};

export const toFirstDayOfMonthDate = (year, monthIndex) => {
  return new Date(year, monthIndex, 1);
};

export const API_DATE_FORMAT_FULL = 'yyyy-MM-dd';
export const API_DATE_FORMAT_YEAR = 'yyyy';
export const API_DATE_TIME_FORMAT_FULL = `yyyy-MM-dd'T'HH:mm:ss'+0000'`;
