import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const LoaderWrapper = styled.div`
    flex: 1,
    alignItems: 'center',
    textAlign: 'center',
  `;

const LoadingIndicator = () => {
  const themeContext = useContext(ThemeContext);
  const StyledProgressBar = withStyles({
    colorPrimary: {
      backgroundColor: `${themeContext.loader.secondaryColor}`,
    },
    barColorPrimary: {
      backgroundColor: `${themeContext.loader.primaryColor}`,
    },
  })(LinearProgress);

  return (
    <LoaderWrapper>
      <StyledProgressBar />
    </LoaderWrapper>
  );
};

export default LoadingIndicator;
