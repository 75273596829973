export const TANGELO = '#F24D00';
export const OLD_LAVENDER = '#737373';
export const CADMIUM_GREEN = '#006633';
export const CADMIUM_GREEN_LIGHT = '#EEF7F2';
export const YELLOW_GREEN = '#99CC33';
export const RICH_ELECTRIC_BLUE = '#0C9FCD';
export const PRUSSIAN_BLUE = '#033454';
export const CAPUT_MORTUUM = '#692F21';
export const CRIMSON_GLORY = '#B00020';
export const UCLA_GOLD = '#FFAE03';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

export const GRAPH_BUYING_COLOR = '#737373';
export const GRAPH_YEAR_PRODUCT_COLOR = '#963100';
export const GRAPH_QUARTER_PRODUCT_COLOR = '#F34E00';
export const GRAPH_MONTH_PRODUCT_COLOR = '#FFB998';
export const GRAPH_VOLUME_COLOR = '#E6E5E3';
export const GRAPH_GREY_FONT_COLOR = '#888888';
export const GRAY1 = '#7F7F7F';
export const GRAY2 = '#B8B3AD';
export const GRAY3 = '#E4E4E4';

export const GRAPH_CONTRACT_COLOR = '#E6E5E3';
export const GRAPH_TRADING_COLOR = '#737373';
export const GRAPH_YEAR_COLOR = '#963100';
export const GRAPH_QUARTER_COLOR = '#F34E00';
export const GRAPH_MONTH_COLOR = '#FFB998';
export const GRAPH_RELEASED_COLOR = '#FFAE03';
export const GRAPH_RELEASING_COLOR = '#805801';

export const COLORS_PRIMARY_MAIN = CADMIUM_GREEN;
export const COLORS_SECONDARY_MAIN = YELLOW_GREEN;
export const COLORS_BACKGROUND_DEFAULT = '#FCFCFC';
export const COLORS_ACTION_HOVER = 'rgba(0, 0, 0, 0.04)';
export const COLORS_ACTION_SELECTED = WHITE;
export const COLORS_ERROR = CRIMSON_GLORY;

export const CHECKBOX_CHECKED_MAIN = '#73A820';
