import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../styles/theme';
import { ThemeProvider } from 'styled-components';
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { materialUiTheme } from '../../styles/materialUITheme';
import { LayoutContextProvider } from './context/layoutContext';
import layouts from '../../constants/layouts';
import MainLayout from './MainLayout/MainLayout';
import FormLayout from './FormLayout/FormLayout';
import ContractLayout from './ContractLayout/ContractLayout';
import PublicLayout from './PublicLayout/PublicLayout';

export const provideTheme = (component) => (
  <MuiThemeProvider theme={createMuiTheme(materialUiTheme)}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {component}
    </ThemeProvider>
  </MuiThemeProvider>
);

const Layout = ({ children, type }) => {
  let LayoutComponent;
  switch (type) {
    case layouts.FORM:
      LayoutComponent = FormLayout;
      break;
    case layouts.CONTRACT:
      LayoutComponent = ContractLayout;
      break;
    case layouts.PUBLIC:
      LayoutComponent = PublicLayout;
      break;
    case layouts.MAIN:
    default:
      LayoutComponent = MainLayout;
      break;
  }

  return (
    provideTheme(
      <LayoutContextProvider>
        <LayoutComponent>{children}</LayoutComponent>
      </LayoutContextProvider>)
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.values(layouts)),
};

export default Layout;
