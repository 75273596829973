import useApiHook from '../../../../hooks/api/useApiHook';
import { useCallback } from 'react';

const endpointBase = '/exchange-products';

export const useGetBestAskHistoryPrices = () => {
  const { get, data, error, loading } = useApiHook(true);

  const getAll = useCallback(
    async (commodity) => {
      const url = `${endpointBase}/bestAskPricesByCommodity?commodity=${commodity}`;
      await get(url, false);
    },
    [get],
  );

  return { getAll, data, error, loading };
};
