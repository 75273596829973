import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import UserMenuContent from '../../../MenuContent/UserMenuContent';
import PopoverMenu from '../../../PopoverMenu/PopoverMenu';

const UserMenu = () => (
  <PopoverMenu icon={<AccountCircleIcon />}>
    <UserMenuContent />
  </PopoverMenu>
);

UserMenu.propTypes = {};

export default UserMenu;
