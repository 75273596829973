import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EleIconSvg } from '../../assets/icons/electricity-sale.svg';

const ElectricitySale = ({ scale = 1 }) => (
  <div>
    <EleIconSvg
      style={{ width: (16 * scale), height: (16 * scale) }}
    />
  </div>
);

ElectricitySale.propTypes = {
  scale: PropTypes.number,
};

export default ElectricitySale;
