import React from 'react';
import { FormattedMessage } from 'react-intl';
import userRoles from '../../../constants/userRoles';

const links = {
  mainCustomers: {
    path: '/',
    label: <FormattedMessage id={'app.menu.customers.label'} />,
    exact: true,
    action: () => sessionStorage.removeItem('customersFilter'),
  },
  mainTransactions: { path: '/', label: <FormattedMessage id={'app.menu.transactions.label'} />, exact: true, action: () => null },
  billingprices: { path: '/billingprices', label: <FormattedMessage id={'app.menu.invoicePrices.label'} />, exact: true, action: () => null },
  transactions: {
    path: '/transactions',
    label: <FormattedMessage id={'app.menu.transactions.label'} />,
    exact: false,
    action: () => sessionStorage.removeItem('transactionsFilter'),
  },
  contracts: {
    path: '/contracts',
    label: <FormattedMessage id={'app.menu.contracts.label'} />,
    exact: false,
    action: () => sessionStorage.removeItem('contractsFilter'),
  },
  users: { path: '/users', label: <FormattedMessage id={'app.menu.users.label'} />, exact: false, action: () => sessionStorage.removeItem('usersFilter') },
  priceDevelopment: {
    path: '/productPricesGraph',
    label: <FormattedMessage id={'app.priceDevelopment.label'} />,
    exact: false,
    action: () => null,
  },
};

const mainMenuLinks = {};
mainMenuLinks[userRoles.ADMIN] = [links.mainCustomers, links.billingprices, links.transactions, links.contracts, links.users, links.priceDevelopment];

mainMenuLinks[userRoles.TRADER] = [links.mainTransactions, links.contracts, links.users, links.priceDevelopment];

mainMenuLinks[userRoles.KAM] = [links.mainCustomers, links.transactions, links.contracts, links.priceDevelopment];

mainMenuLinks[userRoles.ASM] = [links.mainCustomers, links.transactions, links.contracts, links.priceDevelopment];

mainMenuLinks[userRoles.CUSTOMER] = [
  { path: '/', label: <FormattedMessage id={'app.menu.overview.label'} />, exact: true, action: () => null },
  links.contracts,
  { path: '/pricealert', label: <FormattedMessage id={'app.menu.pricealert.label'} />, exact: false, action: () => null },
  links.priceDevelopment,
];

mainMenuLinks[userRoles.CUSTOMER_VIEWER] = [
  { path: '/', label: <FormattedMessage id={'app.menu.overview.label'} />, exact: true, action: () => null },
  links.contracts,
  { path: '/pricealert', label: <FormattedMessage id={'app.menu.pricealert.label'} />, exact: false, action: () => null },
  links.priceDevelopment,
];

export default mainMenuLinks;
