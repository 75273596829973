import useApiHook from '../../../../hooks/api/useApiHook';
import { serializeObjectToUrl } from '../../../../utils/urlHelper';
import { useCallback } from 'react';

const endpointBase = '/exchange-products';

export const useGetAllPublicExchangeProducts = () => {
  const { get, data, error, loading } = useApiHook(true);

  const getAll = useCallback(
    async (query = '') => {
      const url = query ? `${endpointBase}/active-product-prices?${serializeObjectToUrl(query)}` : endpointBase;
      await get(url, false);
    },
    [get],
  );

  return { getAll, data, error, loading };
};
