import axios from 'axios';
import authentication from 'msal-auth';
import { useCallback, useEffect, useReducer, useRef } from 'react';

const actions = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const apiReducer = (state, action) => {
  switch (action.type) {
    case actions.REQUEST:
      return {
        loading: true,
        error: null,
        data: null,
      };

    case actions.SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.data,
      };

    case actions.ERROR:
    default:
      return {
        loading: false,
        error: action.error,
        data: null,
      };
  }
};

axios.interceptors.request.use(async (config) => {
  if (config.withCredentials) {
    const token = await authentication.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
});

const preparePath = (path) => {
  if (path && path[0] !== '/') {
    return `${path}`;
  } else {
    return `${process.env.REACT_APP_API_SERVER}${process.env.REACT_APP_API_URL}${path}`;
  }
};

/**
 * Hook for Api call using Axios
 *
 * @param {boolean} cancellable Should be previous call cancelled if api is called again
 * @returns {object} get method for get request, post method for post request, loading state, error state, data state
 */
const useApiHook = (cancellable = true) => {
  const [state, dispatch] = useReducer(apiReducer, initialState);
  const cancelRef = useRef(null);

  useEffect(() => {
    return cancelRequest;
  }, []);

  /**
     * Run request
     * @param {function} request axios method callback
     */
  const runRequest = useCallback(
    async (request) => {
      if (cancellable) {
        cancelRequest();
      }
      dispatch({ type: actions.REQUEST });

      const source = axios.CancelToken.source();
      cancelRef.current = source.cancel;

      let output;
      try {
        output = await request(source.token);
        dispatch({ type: actions.SUCCESS, data: output.data });
      } catch (e) {
        if (!axios.isCancel(e)) {
          dispatch({ type: actions.ERROR, error: e.response });
          output = e;
        }
      }
      return output;
    }, [cancellable],
  );

  /**
     * Api GET request
     * @param {string} path API url
     */
  const get = useCallback(
    async (path, withCredentials = true, config = {}) => {
      return runRequest(async (cancelToken) => {
        return axios.get(preparePath(path), {
          withCredentials: withCredentials,
          cancelToken: cancelToken,
          ...config,
        });
      });
    }, [runRequest],
  );

  /**
     * Api POST request
     * @param {string} path API url
     * @param {object} post body
     */
  const post = useCallback(
    async (path, data, config = {}, withCredentials = true) => {
      return runRequest(async (cancelToken) => {
        return axios.post(preparePath(path), data, {
          withCredentials: withCredentials,
          cancelToken: cancelToken,
          ...config,
        });
      });
    }, [runRequest],
  );

  /**
     * Api PUT request
     * @param {string} path API url
     * @param {object} put body
     */
  const put = useCallback(
    async (path, data, withCredentials = true) => {
      return runRequest(async (cancelToken) => {
        return axios.put(preparePath(path), data, {
          withCredentials: withCredentials,
          cancelToken: cancelToken,
        });
      });
    }, [runRequest],
  );

  /**
     * Api DELETE request
     * @param {string} path API url
     * @param {object} delete body
     */
  const deleted = useCallback(
    async (path) => {
      return runRequest(async (cancelToken, config = {}, withCredentials = true) => {
        return axios.delete(preparePath(path), {
          withCredentials: withCredentials,
          cancelToken: cancelToken,
          ...config,
        });
      });
    }, [runRequest],
  );

  /**
     * Cancel previous running request
     * @returns {undefined}
     */
  const cancelRequest = () => {
    if (cancelRef?.current) {
      cancelRef.current('Canceled');
    }
  };

  return {
    get,
    post,
    put,
    deleted,
    loading: state.loading,
    error: state.error,
    data: state.data,
  };
};

export default useApiHook;
