import * as fonts from '../constants/fonts';

import {
  CADMIUM_GREEN_LIGHT,
  COLORS_ACTION_HOVER,
  COLORS_ACTION_SELECTED,
  COLORS_BACKGROUND_DEFAULT,
  COLORS_ERROR,
  COLORS_PRIMARY_MAIN,
  COLORS_SECONDARY_MAIN,
  CRIMSON_GLORY,
} from '../constants/colors';

export const materialUiTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440,
    },
  },
  mixins: {
    toolbar: {
      minHeight: '56px',
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: '56px',
      },
      '@media (min-width: 600px)': {
        minHeight: '56px',
      },
    },
  },
  palette: {
    primary: {
      main: COLORS_PRIMARY_MAIN,
    },
    secondary: {
      main: COLORS_SECONDARY_MAIN,
    },
    background: {
      default: COLORS_BACKGROUND_DEFAULT,
    },
    action: {
      hover: COLORS_ACTION_HOVER,
      hoverOpacity: 0.04,
      selected: COLORS_ACTION_SELECTED,
    },
    error: {
      main: COLORS_ERROR,
    },
  },
  typography: {
    fontFamily: `
    ${fonts.FONT_MAIN},
    ${fonts.FONT_SECONDARY},
    ${fonts.FONT_TERTIARY}`,
    h4: {
      fontFamily: `${fonts.FONT_SECONDARY}, ${fonts.FONT_TERTIARY}`,
      fontSize: fonts.FONTS_SIZE_H4,
      textTransform: 'uppercase',
      lineHeight: 1.343003,
      letterSpacing: '0.006715em',
    },
    h5: {
      fontFamily: `${fonts.FONT_SECONDARY}, ${fonts.FONT_TERTIARY}`,
      fontSize: fonts.FONTS_SIZE_H5,
      textTransform: 'uppercase',
      lineHeight: 1.33181,
      letterSpacing: 0,
    },
    subtitle1: {
      fontSize: fonts.FONTS_SIZE_SUBTITLE1,
      fontWeight: 500,
      lineHeight: 1.366,
      letterSpacing: '0.0085375em',
    },
    body1: {
      fontSize: fonts.FONTS_SIZE_BODY1,
      letterSpacing: '0.0284576em',
      lineHeight: 1.19522,
    },
    body2: {
      fontSize: fonts.FONTS_SIZE_BODY2,
      letterSpacing: '0.016254876em',
      lineHeight: 1.30039,
    },
    caption: {
      fontSize: fonts.FONTS_SIZE_CAPTION,
      letterSpacing: '0.030349em',
      lineHeight: 1.21396,
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        borderBottom: '1px solid rgba(0,0,0,0.12)',
      },
    },
    MuiTab: {
      root: {
        fontSize: fonts.FONTS_SIZE_BODY2,
        letterSpacing: '0.0812744em',
        '@media (min-width: 600px)': {
          fontSize: fonts.FONTS_SIZE_BODY2,
          letterSpacing: '0.0812744em',
        },
        '&$selected': {
          color: COLORS_PRIMARY_MAIN,
        },
      },
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: '2rem',
      },
    },
    MuiIcon: {
      fontSizeLarge: {
        fontSize: '2rem',
      },
    },
    MuiSkeleton: {
      text: {
        height: '1.4375rem',
      },
    },
    MuiButton: {
      label: {
        marginTop: 2,
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: '56px',
        '@media (min-width: 600px)': {
          minHeight: '56px',
        },
      },
    },
    MuiTableCell: {
      head: {
        color: '#000000',
        height: '56px',
      },
      body: {
        color: '#666666',
        height: '52px',
      },
      sizeSmall: {
        padding: '0 16px',
      },
    },
    MuiList: {
      root: {
        '& > a.active': {
          backgroundColor: `${CADMIUM_GREEN_LIGHT} !important`,
          color: COLORS_PRIMARY_MAIN,
        },
      },
    },
    MuiListItem: {
      root: {
        '&:hover': {
          backgroundColor: `${CADMIUM_GREEN_LIGHT} !important`,
          color: COLORS_PRIMARY_MAIN,
        },
        color: '#000000',
      },
    },
    MuiBadge: {
      dot: {
        backgroundColor: CRIMSON_GLORY,
      },
      anchorOriginTopRightRectangle: {
        top: '5px',
        right: '5px',
      },
    },
    MuiToggleButton: {
      root: {
        color: '#000000',
        '&$selected': {
          color: '#000000',
          backgroundColor: CADMIUM_GREEN_LIGHT,
        },
      },
    },
  },
  custom: {
    dataTable: {
      header: {
        color: '#666666',
      },
      warningColor: CRIMSON_GLORY,
      placeholderLineHeight: '400px',
    },
  },
};
