import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  GRAY1,
  GRAY2,
} from '../../constants/colors';

const Item = styled.div`
  display: flex;
  margin: 0 10px 10px;
  color: ${(props) => props.active ? GRAY1 : GRAY2};
  cursor: ${(props) => props.onClick ? 'pointer' : 'default'};
`;

const ColorBox = styled.div`
  display: block;
  width: 10px;
  height: 10px;
  margin: 2px 8px 0 0;
  background: ${(props) => props.active ? props.color : GRAY2};
`;

const Label = styled.div`
  flex: 1;
  font-size: 12px;
`;

const LegendItem = ({
  active,
  color,
  label,
  onClick,
}) => {
  return (
    <Item
      active={active}
      onClick={onClick ? () => onClick() : undefined}
    >
      <ColorBox
        active={active}
        color={color}
      />
      <Label>{label}</Label>
    </Item>
  );
};

LegendItem.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default LegendItem;
