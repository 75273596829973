import React from 'react';
import PropTypes from 'prop-types';
import TopBar from './Header/TopBar';
import Container from '@material-ui/core/Container';
import Footer from '../Footer/Footer';

const FormLayout = ({ children }) => (
  <>
    <TopBar />
    <Container maxWidth="md">
      {children}
    </Container>
    <Footer />
  </>
);

FormLayout.propTypes = {
  children: PropTypes.node,
};

export default FormLayout;
