/* eslint-disable no-unused-vars */
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import AppTitle from './AppTitle';
import Container from '@material-ui/core/Container';
import MainMenu from '../Menus/MainMenu';
import UserMenu from '../Menus/UserMenu';
import NotificationsMenu from '../Menus/NotificationsMenu';
import LanguageMenu from '../Menus/LanguageMenu';
import { Box } from '@material-ui/core';
import useAppContext from '../../../../hooks/context/useAppContext';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
}));

const TopBar = () => {
  const classes = useStyles({});
  const { breadCrumbs } = useAppContext();
  return (
    <AppBar position="static">
      <Toolbar>
        <MainMenu />
        <AppTitle />
        <Box>
          <NotificationsMenu />
          <LanguageMenu />
          <UserMenu />
        </Box>
      </Toolbar>
      <Toolbar className={classes.toolbar}>
        <Container maxWidth="xl">{breadCrumbs || ''}</Container>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {};

export default TopBar;
