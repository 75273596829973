import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { BLACK } from '../../constants/colors';
import { commodities } from '../../constants/commodities';

export const LineChartTooltipStyled = styled.div`
  color: ${BLACK};
  font-size: 13px;
  margin: 6px 8px;
  width: 350px;
`;

export const TooltipLabel = styled.div`
  width: 180px;
  margin: 6px;
`;

export const TooltipValue = styled.div`
  flex: 1;
  margin: 6px;
`;

export const TooltipLine = styled.div`
  display: flex;
`;

const LineChartTooltipComponent = ({ entries, commodity, currency }) => {
  const intl = useIntl();
  const entry = entries[0];
  const curveType = entry.dataPoint.curveType;

  const isPrice = curveType === 'PRICE_DEVELOPMENT' && !entry.dataPoint.transactionType;
  const isTransaction = curveType === 'PRICE_DEVELOPMENT' && entry.dataPoint?.purchases?.length > 0;
  const isExchangeRate = curveType === 'EXCHANGE_RATE';

  const data = {
    date: format(parseISO(entry.dataPoint.label), intl.messages['app.dateFormat']),
    price: entry.dataPoint.y,
    name: entry.dataSeries.name,
    purchases: entry.dataPoint?.purchases,
  };
  if (isTransaction) {
    data.quantity = entry.dataPoint.transactionQuantity;
  }

  return (
    <LineChartTooltipStyled>
      {isPrice && (
        <>
          <TooltipLine>
            <TooltipLabel>
              <FormattedMessage id={'app.products.product'} />:
            </TooltipLabel>
            <TooltipValue>{data.name}</TooltipValue>
          </TooltipLine>
          <TooltipLine>
            <TooltipLabel>
              <FormattedMessage id={'app.date'} />:
            </TooltipLabel>
            <TooltipValue>{data.date}</TooltipValue>
          </TooltipLine>
          <TooltipLine>
            <TooltipLabel>
              <FormattedMessage id={'app.priceDevelopment.stockPriceOn'} /> {commodities?.[commodity]?.unit}:
            </TooltipLabel>
            <TooltipValue>
              {data.price} {currency}
            </TooltipValue>
          </TooltipLine>
          <TooltipLine>&nbsp;</TooltipLine>
        </>
      )}
      {isTransaction && (
        <>
          <TooltipLine>
            <TooltipLabel>
              <FormattedMessage id={'app.transactions.label'} />
            </TooltipLabel>
          </TooltipLine>
          {data?.purchases?.map((transaction, key) => {
            const currentDateTime = new Date(transaction.createdAd);
            const year = currentDateTime.getUTCFullYear();
            const month = currentDateTime.getUTCMonth();
            const day = currentDateTime.getUTCDate();
            const hours = currentDateTime.getUTCHours();
            const minutes = currentDateTime.getUTCMinutes();

            const transactionType = transaction.transactionType;
            const transactionTypeLabel = `app.priceDevelopment.${transactionType.toLowerCase()}`;

            return (
              <div key={key}>
                <TooltipLine>
                  <TooltipLabel>
                    <FormattedMessage id={'app.priceDevelopment.transactionDate'} />:
                  </TooltipLabel>
                  <TooltipValue>
                    <FormattedDate value={new Date(year, month, day)} />
                    &nbsp;
                    <FormattedTime value={new Date(year, month, day, hours, minutes)} />
                  </TooltipValue>
                </TooltipLine>
                <TooltipLine>
                  <TooltipLabel>
                    <FormattedMessage id={'app.priceDevelopment.transactionType'} />:
                  </TooltipLabel>
                  <TooltipValue>
                    <FormattedMessage id={transactionTypeLabel} />
                  </TooltipValue>
                </TooltipLine>
                <TooltipLine>
                  <TooltipLabel>
                    <FormattedMessage id={'app.amount'} /> ({commodities?.[commodity]?.unit}):
                  </TooltipLabel>
                  <TooltipValue>{transaction.quantity}</TooltipValue>
                </TooltipLine>
                <TooltipLine>&nbsp;</TooltipLine>
              </div>
            );
          })}
        </>
      )}
      {isExchangeRate && (
        <>
          <TooltipLine>
            <TooltipLabel>
              <FormattedMessage id={'app.date'} />:
            </TooltipLabel>
            <TooltipValue>{data.date}</TooltipValue>
          </TooltipLine>
          <TooltipLine>
            <TooltipLabel>
              <FormattedMessage id={'app.exchangeRate'} />:
            </TooltipLabel>
            <TooltipValue>{data.price} CZK</TooltipValue>
          </TooltipLine>
        </>
      )}
    </LineChartTooltipStyled>
  );
};

LineChartTooltipComponent.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      dataPoint: PropTypes.shape({
        y: PropTypes.number,
        label: PropTypes.string,
        curveType: PropTypes.oneOf(['PRICE_DEVELOPMENT', 'EXCHANGE_RATE']),
        transactionType: PropTypes.string,
        transactionQuantity: PropTypes.string,
      }),
      dataSeries: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
  commodity: PropTypes.oneOf(Object.keys(commodities)).isRequired,
  currency: PropTypes.string,
};

export default LineChartTooltipComponent;
