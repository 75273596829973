import Grid from '@material-ui/core/Grid';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import subMonths from 'date-fns/subMonths';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import CustomTextField from '../../../../components/CustomizedComponents/CustomTextField';
import { DatePicker } from '../../../../components/DatePicker';
import { LegendItem, PriceDevelopmentChart } from '../../../../components/PriceDevelopmentChart';
import { CADMIUM_GREEN, PRUSSIAN_BLUE, TANGELO, UCLA_GOLD } from '../../../../constants/colors';
import { commodities } from '../../../../constants/commodities';
import { API_DATE_FORMAT_FULL } from '../../../../constants/date';
import adjustColorLightness from '../../../../utils/adjustColorLightness';
import { useGetAllPublicExchangeProducts } from '../../hooks/api/publicExchangeProductsApi';

const useStyle = makeStyles(() => ({
  textField: {
    display: 'flex',
    justifyContent: 'end',
    height: '40px',
    '& div select': {
      padding: '10px 10px 10px 10px',
    },
  },
  currencyTextField: {
    minWidth: '75px',
  },
}));

const Legend = styled.div`
  margin: 20px 0 0 0;
  margin: 20px 10px 10px;
`;

const ChartWrapper = styled.div`
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
  color: #666666;
`;

const HeaderWrapper = styled.div`
  margin: 20px;
`;

const HeadlineWrapper = styled.div`
  font-size: 1.7rem;
  display: flex;
  color: ${(props) => props.theme.custom.dataTable.header.color};
`;

const IconWrapper = styled.div`
  margin: 0 10px 0 0;
`;

const dateIsoFormat = 'yyyy-MM-dd';

const chartHasData = (apiResponse) => {
  return !!(apiResponse && apiResponse.products && apiResponse.products.length && apiResponse.rates && apiResponse.rates.length);
};

let allDates = [];

const alignGraphDataToTimeLine = (timeLine, graphData, dateKey, valueKey) => {
  let lastFoundIndex = null;
  return timeLine.reduce((output, date) => {
    const index = graphData.findIndex((item) => {
      return item[dateKey] === date;
    });

    if (index > -1) {
      lastFoundIndex = index;
      output.push(graphData[index]);
    } else if (lastFoundIndex && graphData.length > lastFoundIndex + 1) {
      output.push({
        ...graphData[lastFoundIndex],
        [dateKey]: date,
      });
    } else if (lastFoundIndex === null) {
      output.push({
        ...graphData[0],
        [dateKey]: date,
        [valueKey]: null,
      });
    }

    return output;
  }, []);
};

const PublicPriceDevelopment = ({ type, currency, changeCurrency }) => {
  const classes = useStyle();
  const intl = useIntl();
  const theme = useTheme();

  // products API needs a year to get initial products
  const currentDate = new Date();
  const currentDateFull = format(currentDate, API_DATE_FORMAT_FULL);

  // API handlers
  const { getAll: getExchangeProducts, data: exchangeProductsData } = useGetAllPublicExchangeProducts();

  // local state
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [productsLegend, setProductsLegend] = useState([]);
  const [chartEntries, setChartEntries] = useState({
    priceDevelopment: [],
    exchangeRate: [],
  });
  const [exchangeRateLegend, setExchangeRateLegend] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [priceCurves, setPriceCurves] = useState([]);
  const [timeFrame, setTimeFrame] = useState({
    from: format(subMonths(currentDate, 6), API_DATE_FORMAT_FULL),
    to: currentDateFull,
  });
  const [exchangeRate, setExchangeRate] = useState(null);

  // handler for user selection of exchange rate curves
  const chooseExchangeRate = (id) => {
    if (exchangeRate === id) setExchangeRate(null);
    else setExchangeRate(id);
  };

  const commodityObj = commodities?.[type] || null;

  // handler for user selection of price curves
  const choosePrice = (id) => {
    if (priceCurves.includes(id)) setPriceCurves(priceCurves.filter((item) => item !== id));
    else setPriceCurves([...priceCurves, id]);
  };

  useEffect(() => {
    allDates = [];
    const dateTo = new Date(timeFrame.to);
    let currentDate = new Date(timeFrame.from);
    while (dateTo - currentDate > 0) {
      allDates.push(format(currentDate, dateIsoFormat));
      currentDate = addDays(currentDate, 1);
    }
  }, [timeFrame]);

  useEffect(() => {
    if (exchangeProductsData) {
      // categorize products by their type, so we can divide them to columns in the legend
      // and generate color shades from the main colors
      const productsAnnual = exchangeProductsData.products.filter(({ exchangeProduct }) => exchangeProduct.productType.id.startsWith('A'));
      productsAnnual.forEach((item, index) => {
        item.color = adjustColorLightness(TANGELO, index * (150 / productsAnnual.length));
      });
      const productsQuarter = exchangeProductsData.products.filter(({ exchangeProduct }) => exchangeProduct.productType.id.startsWith('Q'));
      productsQuarter.forEach((item, index) => {
        item.color = adjustColorLightness(PRUSSIAN_BLUE, index * (150 / productsQuarter.length));
      });
      const productsMonthly = exchangeProductsData.products.filter(({ exchangeProduct }) => exchangeProduct.productType.id.startsWith('M'));
      productsMonthly.forEach((item, index) => {
        item.color = adjustColorLightness(CADMIUM_GREEN, index * (150 / productsMonthly.length));
      });
      // consolidate the product into groups so we can iterate over them when displaying legend
      setProductsLegend([productsAnnual, productsQuarter, productsMonthly]);

      // preselecting first product of every type
      setPriceCurves([
        ...(productsAnnual.length ? [productsAnnual[0].exchangeProduct.id] : []),
        ...(productsQuarter.length ? [productsQuarter[0].exchangeProduct.id] : []),
        ...(productsMonthly.length ? [productsMonthly[0].exchangeProduct.id] : []),
      ]);
    }
  }, [exchangeProductsData]);

  // first API call to get the products
  useEffect(() => {
    const query = {
      startDate: timeFrame.from,
      endDate: timeFrame.to,
      commodity: type,
    };
    (async () => {
      await getExchangeProducts(query);
    })();
  }, [getExchangeProducts, type, timeFrame]);

  useEffect(() => {
    if (exchangeProductsData) {
      const priceDevelopment = exchangeProductsData.products.map(({ prices, exchangeProduct }) => {
        const dataPoints = prices.map((dataPoint) => {
          const output = {
            label: format(new Date(dataPoint.validTo), dateIsoFormat),
            y: dataPoint[`price${currency}`],
            curveType: 'PRICE_DEVELOPMENT',
          };

          if (dataPoint?.purchases && dataPoint.purchases.length > 0) {
            output.markerType = 'circle';
            output.markerSize = '8';
            output.purchases = dataPoint?.purchases;
          } else output.markerSize = '0';

          return output;
        });

        const timeLineData = alignGraphDataToTimeLine(allDates, dataPoints, 'label', 'y');
        return {
          type: exchangeProduct.productType.id,
          id: exchangeProduct.id,
          name: exchangeProduct.name,
          dataPoints: timeLineData,
        };
      });

      const exchangeRate = exchangeProductsData.rates.map(({ rates, currencyFrom, currencyTo }) => {
        const dataPoints = rates.map((dataPoint) => ({
          curveType: 'EXCHANGE_RATE',
          label: format(new Date(dataPoint.validTo), dateIsoFormat),
          y: dataPoint.value,
        }));

        const timeLineData = alignGraphDataToTimeLine(allDates, dataPoints, 'label', 'y');

        return {
          id: `${currencyFrom.id}${currencyTo.id}`,
          name: `${currencyFrom.id} > ${currencyTo.id}`,
          dataPoints: timeLineData,
        };
      });

      // mapping for exchange rate values
      const exchangeRateLegendResult = exchangeRate.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      setExchangeRateLegend(exchangeRateLegendResult);
      setChartEntries({
        priceDevelopment,
        exchangeRate,
      });
    }
  }, [exchangeProductsData, currency]);
  // this runs after each user selection (hide/unhide) of particular curves
  useEffect(() => {
    const priceDevelopmentCurves = chartEntries.priceDevelopment
      .filter((item) => priceCurves.includes(item.id))
      .map((item) => {
        return {
          color: productsLegend.flat().filter((legendItem) => legendItem.exchangeProduct.id === item.id)[0].color,
          dataPoints: item.dataPoints,
          markerType: 'circle',
          name: item.name,
          type: 'line',
        };
      });

    const exchangeRateCurves = chartEntries.exchangeRate
      .filter((item) => exchangeRate === item.id)
      .map((item) => {
        return {
          axisYType: 'secondary',
          color: UCLA_GOLD,
          dataPoints: item.dataPoints,
          lineDashType: 'dash',
          markerType: 'circle',
          markerSize: '0',
          name: item.name,
          type: 'line',
        };
      });

    setChartData([...priceDevelopmentCurves, ...exchangeRateCurves]);
  }, [priceCurves, exchangeRate, chartEntries, productsLegend]);

  const icon = React.cloneElement(commodityObj.icon, { scale: 2 });

  return (
    <>
      <HeaderWrapper>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item xs>
            <HeadlineWrapper theme={theme}>
              <IconWrapper>{icon}</IconWrapper>
              {intl.messages[commodityObj.title]}
            </HeadlineWrapper>
          </Grid>
          <Grid item xs={2} className={classes.textField}>
            <CustomTextField
              className={classes.currencyTextField}
              onChange={() => {
                changeCurrency(currency === 'CZK' ? 'EUR' : 'CZK');
              }}
              select
              value={currency}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option key={'CZK'} value={'CZK'}>CZK</option>
              <option key={'EUR'} value={'EUR'}>EUR</option>
            </CustomTextField>
          </Grid>
          <Grid item xs="auto">
            <DatePicker
              from={timeFrame.from}
              to={timeFrame.to}
              onSave={(newTimeFrame) => setTimeFrame(newTimeFrame)}
              onOpen={(isOpen) => setDatePickerOpen(isOpen)}
            />
          </Grid>
        </Grid>
      </HeaderWrapper>
      <ChartWrapper>
        {chartHasData(exchangeProductsData) && (
          <PriceDevelopmentChart
            axisYTitle={`${intl.messages['app.price']} (${currency})`}
            axisY2Title={intl.messages['app.exchangeRate']}
            chartData={chartData}
            tooltipDisabled={datePickerOpen}
            commodity={type}
            currency={currency}
          />
        )}
        {!chartHasData(exchangeProductsData) && <FormattedMessage id={'app.priceDevelopment.noData'} />}
      </ChartWrapper>
      <Legend>
        <Grid container justify="center">
          {productsLegend.map((product, index) => {
            return (
              <Grid key={index} item xs={3}>
                {product.map((item) => {
                  return (
                    <LegendItem
                      active={priceCurves.includes(item.exchangeProduct.id)}
                      key={`${item.exchangeProduct.idContract}_${item.exchangeProduct.id}`}
                      label={item.exchangeProduct.name}
                      onClick={() => choosePrice(item.exchangeProduct.id)}
                      color={item.color}
                    />
                  );
                })}
              </Grid>
            );
          })}
          <Grid item xs={3}>
            {exchangeRateLegend.map((item) => {
              return (
                <LegendItem key={item.id} active={item.id === exchangeRate} label={item.label} onClick={() => chooseExchangeRate(item.id)} color={UCLA_GOLD} />
              );
            })}
          </Grid>
        </Grid>
      </Legend>
    </>
  );
};

PublicPriceDevelopment.propTypes = {
  type: PropTypes.oneOf(Object.keys(commodities)).isRequired,
  currency: PropTypes.string,
  changeCurrency: PropTypes.func,
};

export default PublicPriceDevelopment;
