import { useCallback, useContext } from 'react';
import { appContext } from '../../store/appContext';
import { appContextActions } from '../../store/appContextActions';

const useAppContext = () => {
  const { state, dispatch } = useContext(appContext);

  const setUser = useCallback(
    (user) => {
      dispatch({ type: appContextActions.SET_USER, user: user });
    },
    [dispatch],
  );

  const setEnums = useCallback(
    (enums) => {
      dispatch({ type: appContextActions.SET_ENUMS, enums: enums });
    },
    [dispatch],
  );

  const getEnums = useCallback(() => state?.enums, [state]);

  const setAuthorized = useCallback(
    (authorized) => {
      dispatch({ type: appContextActions.SET_AUTHORIZED, authorized: authorized });
    },
    [dispatch],
  );

  const setSignedIn = useCallback(
    (signedIn) => {
      dispatch({ type: appContextActions.SET_SIGNED_IN, signedIn: signedIn });
    },
    [dispatch],
  );

  const setBreadCrumbs = useCallback(
    (breadCrumbs) => {
      dispatch({ type: appContextActions.SET_BREAD_CRUMBS, breadCrumbs: breadCrumbs });
    },
    [dispatch],
  );

  const setBackNavigation = useCallback(
    (location) => {
      dispatch({ type: appContextActions.SET_BACK_NAVIGATION, location: location });
    },
    [dispatch],
  );

  const setNotificationCount = useCallback(
    (count) => {
      dispatch({ type: appContextActions.SET_NOTIFICATION_COUNT, count: count });
    },
    [dispatch],
  );

  const getAuthorized = useCallback(() => state?.authorized, [state]);

  const getSignedIn = useCallback(() => state?.signedIn, [state]);

  const getUser = useCallback(() => state?.user, [state]);

  const getCustomerIds = useCallback(() => state?.user?.customerIds, [state]);

  const getUserRole = useCallback(() => state?.user?.role, [state]);

  const getUserLanguage = useCallback(() => state?.user?.language, [state]);

  const getNotificationCount = useCallback(() => state?.notificationsCount, [state]);

  return {
    setEnums,
    setBreadCrumbs,
    setBackNavigation,
    setNotificationCount,
    getNotificationCount,
    getEnums,
    setAuthorized,
    getAuthorized,
    setSignedIn,
    getSignedIn,
    setUser,
    getUser,
    getUserRole,
    getCustomerIds,
    getUserLanguage,
    authorized: state?.authorized,
    signedIn: state?.signedIn,
    user: state?.user,
    breadCrumbs: state?.breadCrumbs,
    backNavigation: state?.backNavigation,
    notificationsCount: state?.notificationsCount,
    enums: state?.enums,
  };
};

export default useAppContext;
