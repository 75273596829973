import React from 'react';
import { FormattedMessage } from 'react-intl';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import useBreadCrumbs from '../../hooks/breadCrumbs/useBreadCrumbs';
import BestAskPrices from '../PublicAskPriceELEPage/components/BestAskPrices/BestAskPrices';

const PublicAskPriceCZVTPPage = () => {
  useBreadCrumbs(<FormattedMessage id={'app.publicaskprice.czvtp'} />);
  return (
    <>
      <SectionHeader>
        <FormattedMessage id={'app.publicaskprice.czvtp'} />
      </SectionHeader>
      <BestAskPrices commodity={'GAS_CZVTP'} />
    </>
  );
};

export default PublicAskPriceCZVTPPage;
