export const FONTS_SIZE_H4 = '2.326875rem';
export const FONTS_SIZE_H5 = '1.6425rem';
export const FONTS_SIZE_SUBTITLE1 = '1.098125rem';
export const FONTS_SIZE_BODY1 = '1.098125rem';
export const FONTS_SIZE_BODY2 = '0.96125rem';
export const FONTS_SIZE_CAPTION = '0.82375rem';


export const FONT_MAIN = 'Nimbus';
export const FONT_SECONDARY = 'Futura Medium';
export const FONT_TERTIARY = 'Arial';
