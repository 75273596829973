import React from 'react';
import PropTypes from 'prop-types';
import TopBar from '../MainLayout/Header/TopBar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';

const ScrollableBody = styled.div`
  flex: 1;
  overflow: auto;
`;

export const SCROLLABLE_BODY = 'scrollableBody';
export const FIXED_FOOTER_DIV_ID = 'fixedFooter';
export const SNACKBAR_DIV = 'snackbar';

const ContractLayout = ({ children }) => {
  return (
    <>
      <ScrollableBody id={SCROLLABLE_BODY}>
        <TopBar />
        {children}
        <Footer />
      </ScrollableBody>
      <div id={FIXED_FOOTER_DIV_ID} />
      <div id={SNACKBAR_DIV} />
    </>
  );
};

ContractLayout.propTypes = {
  children: PropTypes.node,
};

export default ContractLayout;
