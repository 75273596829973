import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import authentication from 'msal-auth';

import userRoles from '../../constants/userRoles';
import useAppContext from '../../hooks/context/useAppContext';
import { useAuthorize } from './hooks/api/authorizationApi';
import { useEnums } from './hooks/api/enumsApi';
import { useGetNotifications } from './hooks/api/notificationApi';
import { useLocation } from 'react-router-dom';
import { supportedLanguages } from '../../localizations';

/**
 * Application wrapper for processing before application is mounted
 *
 * @param {node} children children nodes
 * @returns {node} returns wrapped application
 */
const BootstrapManager = ({ children }) => {
  const { authorize, data: authorizeData, loading, error: authorizeError } = useAuthorize();
  const { getEnums, data: enumsData } = useEnums();
  const { getNotifications, data: notificationsData } = useGetNotifications();
  const location = useLocation();

  const {
    setUser,
    setEnums,
    authorized: contextAuthorized,
    setAuthorized: setContextAuthorized,
    setNotificationCount,
    signedIn,
    setSignedIn,
  } = useAppContext();

  useEffect(() => {
    signedIn &&
      (async () => {
        await authorize();
      })();
  }, [authorize, getEnums, signedIn]);

  // eslint-disable-next-line require-jsdoc, no-unused-vars
  function getLanguageCode(languageCode) {
    return supportedLanguages.find((supportedLanguage) => supportedLanguage.code === languageCode)?.languageCode ?? 'cs';
  }

  useEffect(() => {
    if (authorizeData) {
      const user = authorizeData?.user;
      user.role = userRoles[authorizeData?.user?.role];
      user.customers = authorizeData?.user?.customers;
      user.customerIds =
        authorizeData?.user?.customers && authorizeData?.user?.customers.length > 0 ? authorizeData?.user?.customers.map((customer) => customer.id) : null;
      const customers = authorizeData?.user?.customers;
      if (customers.length > 0) {
        user.organization = customers[customers.length - 1].name;
      }
      user.language = getLanguageCode(authorizeData?.user.languague);
      user.initialRowsPerPage = user.role === userRoles.CUSTOMER || user.role === userRoles.CUSTOMER_VIEWER ? 10 : 100;
      setUser(user);
      (async () => {
        await getEnums();
      })();
    }
  }, [setUser, getEnums, authorizeData]);

  useEffect(() => {
    if (enumsData) {
      setEnums(enumsData);
    }
    if (authorizeData && enumsData) {
      setContextAuthorized(true);
    }
  }, [setEnums, enumsData, authorizeData, setContextAuthorized]);

  useEffect(() => {
    authorizeError?.data?.location && window.location.replace(authorizeError?.data?.location);
  }, [authorizeError]);

  useEffect(() => {}, [contextAuthorized]);

  useEffect(() => {
    signedIn &&
      (async () => {
        await getNotifications();
      })();
  }, [location, getNotifications, signedIn]);

  useEffect(() => {
    (async () => {
      const token = await authentication.getToken();
      if (token) {
        setSignedIn(true);
      }
    })();
  }, [setSignedIn]);

  useEffect(() => {
    if (notificationsData) {
      setNotificationCount(notificationsData.length);
    } else {
      setNotificationCount(0);
    }
  }, [setNotificationCount, notificationsData]);

  return (
    <>
      {contextAuthorized && <>{children}</>}
      {loading && <>Loading</>}
    </>
  );
};

BootstrapManager.propTypes = {
  children: PropTypes.node,
};

export default BootstrapManager;
