import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { FormattedMessage } from 'react-intl';
import { Box } from '@material-ui/core';
import { SCROLLABLE_BODY } from '../MainLayout/MainLayout';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(0, 4),
    marginTop: 'auto',
  },
  footerContent: {
    padding: theme.spacing(4, 0),
    borderTop: `1px solid #e4e4e4`,
    marginTop: '2rem',
  },
  footerContentGrid: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  copyright: {
    display: 'inline-flex',
    color: '#e4e4e4',
    alignItems: 'center',
  },
  scrollToTopGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  scrollToTop: {
    display: 'inline-flex',
    alignItems: 'center',
    color: '#026735',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
}));

const Footer = () => {
  const scrollToTop = () => {
    const element = document.getElementById(SCROLLABLE_BODY) || document.getElementById('root');
    element && element.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  const classes = useStyles({});
  return (
    <Container maxWidth="xl" className={classes.footer}>
      <Box className={classes.footerContent}>
        <Grid container className={classes.footerContentGrid}>
          <Grid item xs={6}>
            <Typography style={{ fontSize: '0.9rem' }}>Copyright 2020 ČEZ, a.s.</Typography>
          </Grid>
          <Grid item xs={6} className={classes.scrollToTopGrid}>
            <Typography className={classes.scrollToTop} onClick={scrollToTop}>
              <ExpandLessIcon />
              <FormattedMessage id={'app.layout.up'} />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Footer;
