import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as GasCzvtpIcon } from '../../assets/icons/gas-czvtp.svg';

const GasCzvtp = ({ scale = 1 }) => (
  <div>
    <GasCzvtpIcon
      style={{ width: (16 * scale), height: (16 * scale) }}
    />
  </div>
);

GasCzvtp.propTypes = {
  scale: PropTypes.number,
};

export default GasCzvtp;
