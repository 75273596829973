import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

const styles = {
  root: {
    width: '55%',
    height: 'auto',
    marginBottom: '20px',
    marginRight: '10px',
  },
};

const CustomTextField = (props) => {
  const intl = useIntl();
  const { title, classes, children, className, value, ...other } = props;
  const shrink = typeof value === 'number' || typeof value === 'boolean' ? true : !!value;

  return (
    <TextField
      InputLabelProps={{ shrink }}
      className={clsx(classes.root, className)}
      label={title ? intl.formatMessage({ id: title }) : ''}
      value={value}
      {...other}>
      {children}
    </TextField>
  );
};

CustomTextField.propTypes = {
  title: PropTypes.any,
  classes: PropTypes.any,
  children: PropTypes.node,
  value: PropTypes.any,
};

export default withStyles(styles)(CustomTextField);
