import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { Popover } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const PopoverMenu = ({ icon, children }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = (openMenu) => {
    setOpen(openMenu);
  };

  return (
    <>
      <IconButton edge="start" color="inherit" onClick={() => handleToggle(true)} ref={anchorRef}>
        {icon}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <ClickAwayListener onClickAway={() => handleToggle(false)}>
          <div onClick={() => handleToggle(false)}>{children}</div>
        </ClickAwayListener>
      </Popover>
    </>
  );
};

PopoverMenu.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
};

export default PopoverMenu;
