import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CardRow from '../../../../components/CardRow/CardRow';
import CardRowCard from '../../../../components/CardRow/CardRowCard';
import { commodities } from '../../../../constants/commodities';
import PublicPriceDevelopment from './PublicPriceDevelopment';

const COMMODITIES = [commodities.ELE, commodities.GAS_CZVTP, commodities.GAS_THE];

const ProductPrices = () => {
  const [currency, setCurrency] = useState('EUR');

  const changeCurrency = (newCurrency) => {
    localStorage.setItem('currencyForGraph', newCurrency);
    setCurrency(newCurrency);
  };

  useEffect(() => {
    const savedCurrency = localStorage.getItem('currencyForGraph');

    if (savedCurrency != null && savedCurrency !== currency) {
      setCurrency(savedCurrency);
    }
  }, [currency]);

  return (
    COMMODITIES.map((commodity, index) =>
      <CardRow key={index}>
        <CardRowCard key={index}>
          <PublicPriceDevelopment
            key={index}
            type={commodity.key}
            years={commodity.years}
            currency={currency}
            changeCurrency={changeCurrency}
          />
        </CardRowCard>
      </CardRow>)
  );
};

ProductPrices.propTypes = {
  customerId: PropTypes.number,
};

export default ProductPrices;
