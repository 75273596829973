import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Card = styled.div`
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 5px #b0b0b0;
  margin-bottom: 20px;
  @media all and (min-width: ${(props) => props.theme.sizes.medium}) {
    flex: 1 1;
  }
`;

const CardRowCard = ({ children, className, showBorder = true }) => {
  return (
    <Card className={className} style={{ visibility: showBorder ? 'visible' : 'hidden' }}>
      {children}
    </Card>
  );
};

CardRowCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  showBorder: PropTypes.bool,
};

export default CardRowCard;
