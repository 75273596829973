import { actions } from './actions';
import createContextStore from '../../../utils/contextStore/createContextStore';

const initialState = {};

const handlers = {
  [actions.SET_LAYOUT]: (state, action) => {
    if (action?.layout) {
      return { ...state, layout: action.layout };
    }
    return state;
  },
};

export const [layoutContext, LayoutContextProvider] = createContextStore(initialState, handlers);
