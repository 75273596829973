export const serializeObjectToUrl = (query, parentKey) => {
  return Object.keys(query).reduce((accumulator, key) => {
    const currentStringKey = parentKey ? `${parentKey}[${key}]` : key;
    if (typeof query[key] === 'object' && !Array.isArray(query[key])) {
      accumulator.push(serializeObjectToUrl(query[key], currentStringKey));
    } else if (typeof query[key] === 'object' && Array.isArray(query[key])) {
      accumulator.push(`${currentStringKey}=${query[key].join(',')}`);
    } else {
      accumulator.push(`${currentStringKey}=${query[key]}`);
    }
    return accumulator;
  }, []).join('&');
};

