import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EleIconSvg } from '../../assets/icons/electricity-buy.svg';

const ElectricityBuy = ({ scale = 1 }) => (
  <div>
    <EleIconSvg
      style={{ width: (16 * scale), height: (16 * scale) }}
    />
  </div>
);

ElectricityBuy.propTypes = {
  scale: PropTypes.number,
};

export default ElectricityBuy;
