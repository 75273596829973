import userRoles from '../../../constants/userRoles';

const links = {
  profile: { path: '/profile', label: 'app.userMenu.profile', exact: true },
  tradingConfig: { path: '/trading-config', label: 'app.userMenu.configuration', exact: false },
  priceDevelopment: { path: '/price-development', label: 'app.userMenu.priceDevelopment', exact: false },
  notificationsSettings: { path: '/settings/notifications', label: 'app.userMenu.notificationsSettings', exact: false },
  mobileCard: { path: '/mobileCard', label: 'app.userMenu.mobileCard', exact: true },
};

const userMenuLinks = {};
userMenuLinks[userRoles.ADMIN] = [links.profile, links.notificationsSettings, links.tradingConfig, links.mobileCard];
userMenuLinks[userRoles.TRADER] = [links.profile, links.mobileCard];
userMenuLinks[userRoles.KAM] = [links.profile, links.notificationsSettings, links.mobileCard];
userMenuLinks[userRoles.ASM] = [links.profile, links.notificationsSettings, links.mobileCard];
userMenuLinks[userRoles.CUSTOMER] = [links.profile, links.notificationsSettings, links.mobileCard];
userMenuLinks[userRoles.CUSTOMER_VIEWER] = [links.profile, links.notificationsSettings, links.mobileCard];

export default userMenuLinks;
