import { useCallback } from 'react';
import useApiHook from './useApiHook';
import authentication from 'msal-auth';
import useAppContext from '../context/useAppContext';
import { useHistory } from 'react-router';

export const useAuthentication = () => {
  const { post, data, error, loading } = useApiHook(true);
  const { setAuthorized, setSignedIn } = useAppContext();
  const history = useHistory();

  const logout = useCallback(async () => {
    history.push('/');
    setAuthorized(false);
    setSignedIn(false);
    sessionStorage.removeItem('customersFilter');
    sessionStorage.removeItem('transactionsFilter');
    sessionStorage.removeItem('contractsFilter');
    sessionStorage.removeItem('usersFilter');
    await post(`${process.env.REACT_APP_API_SERVER}/logout`);
    await authentication.logout();
  }, [post, setAuthorized, setSignedIn, history]);

  return { logout, data, error, loading };
};
