import React, { useEffect, useState, Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { getSupportLanguage, messages } from '../src/localizations';
import layouts from './constants/layouts';
import MainRouter from './router/MainRouter';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import BootstrapManager from './components/BootstrapManager';
import Layout from './components/Layout/Layout';
import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator';
import { AppContextProvider } from './store/appContext';
import './styles/global.css';
import useAppContext from './hooks/context/useAppContext';

import PublicProductPricesPage from './pages/PublicProductPricesPage';
import PublicAskPriceELEPage from './pages/PublicAskPriceELEPage';
import PublicAskPriceCZVTPPage from './pages/PublicAskPriceCZVTPPage';
import PublicAskPriceTHEPage from './pages/PublicAskPriceTHEPage';

const locale = getSupportLanguage();

export const INTL_VOLUME_FORMAT = 'volume';
export const INTL_CURRENCY_FORMAT = {
  CZK: 'CZK',
  EUR: 'EUR',
};

const App = () => (
  <AppContextProvider>
    <Router>
      {provideIntl(
        <Switch>
          {/* Public routes are here */}
          <Route
            path="/productPricesGraph"
            render={(props) => (
              <Layout type={layouts.PUBLIC}>
                <Suspense fallback={<LoadingIndicator />}>
                  <PublicProductPricesPage {...props} />
                </Suspense>
              </Layout>
            )}
          />
          <Route
            path="/bestAskPriceHistoryELE"
            render={(props) => (
              <Layout type={layouts.PUBLIC}>
                <Suspense fallback={<LoadingIndicator />}>
                  <PublicAskPriceELEPage {...props} />
                </Suspense>
              </Layout>
            )}
          />
          <Route
            path="/bestAskPriceHistoryCZVTP"
            render={(props) => (
              <Layout type={layouts.PUBLIC}>
                <Suspense fallback={<LoadingIndicator />}>
                  <PublicAskPriceCZVTPPage {...props} />
                </Suspense>
              </Layout>
            )}
          />
          <Route
            path="/bestAskPriceHistoryTHE"
            render={(props) => (
              <Layout type={layouts.PUBLIC}>
                <Suspense fallback={<LoadingIndicator />}>
                  <PublicAskPriceTHEPage {...props} />
                </Suspense>
              </Layout>
            )}
          />
          <BootstrapManager>
            <MainRouter />
          </BootstrapManager>
        </Switch>,
      )}
    </Router>
  </AppContextProvider>
);

const IntlProviderHelper = ({ children }) => {
  const { getUserLanguage } = useAppContext();
  const [language, setLanguage] = useState(locale);

  useEffect(() => {
    const userLanguage = getUserLanguage();
    userLanguage && setLanguage(userLanguage);
  }, [getUserLanguage]);
  return (
    <IntlProvider
      defaultLocale={language}
      messages={messages[language]}
      locale={language}
      formats={{
        number: {
          [INTL_VOLUME_FORMAT]: {
            maximumFractionDigits: 3,
          },
          [INTL_CURRENCY_FORMAT.CZK]: {
            maximumFractionDigits: 2,
          },
          [INTL_CURRENCY_FORMAT.EUR]: {
            maximumFractionDigits: 3,
          },
        },
      }}>
      {children}
    </IntlProvider>
  );
};

export const provideIntl = (component) => <IntlProviderHelper>{component}</IntlProviderHelper>;

export default App;
