const userRoles = {
  ADMIN: 'ADMIN',
  KAM: 'KAM',
  ASM: 'ASM',
  TRADER: 'TRADER',
  CUSTOMER: 'CUSTOMER',
  CUSTOMER_MULTIPLE_COTRACTS: 'CUSTOMER_MULTIPLE_COTRACTS',
  CUSTOMER_VIEWER: 'CUSTOMER_VIEWER',
  CUSTOMER_VIEWER_MULTIPLE_COTRACTS: 'CUSTOMER_VIEWER_MULTIPLE_COTRACTS',
};

export default userRoles;
