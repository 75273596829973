import { appContextActions } from './appContextActions';
import createContextStore from '../utils/contextStore/createContextStore';

const initialState = { user: {}, authorized: true, breadCrumbs: null, notificationsCount: 0 };

const handlers = {
  [appContextActions.SET_USER]: (state, action) => {
    if (action?.user) {
      return { ...state, user: action.user };
    }
    return state;
  },
  [appContextActions.SET_ENUMS]: (state, action) => {
    if (action?.enums) {
      return { ...state, enums: action.enums };
    }
    return state;
  },
  [appContextActions.SET_AUTHORIZED]: (state, action) => {
    if (typeof action?.authorized === 'boolean') {
      return { ...state, authorized: action.authorized };
    }
    return state;
  },

  [appContextActions.SET_SIGNED_IN]: (state, action) => {
    if (typeof action?.signedIn === 'boolean') {
      return { ...state, signedIn: action.signedIn };
    }
    return state;
  },
  [appContextActions.SET_BREAD_CRUMBS]: (state, action) => {
    if (typeof action.breadCrumbs !== 'undefined') {
      return { ...state, breadCrumbs: action.breadCrumbs };
    }
    return state;
  },
  [appContextActions.SET_BACK_NAVIGATION]: (state, action) => {
    if (typeof action.location !== 'undefined') {
      return { ...state, backNavigation: action.location };
    }
    return state;
  },
  [appContextActions.SET_NOTIFICATION_COUNT]: (state, action) => {
    if (typeof action.count !== 'undefined') {
      return { ...state, notificationsCount: action.count };
    }
    return state;
  },
};

export const [appContext, AppContextProvider] = createContextStore(initialState, handlers);
