import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CardRow from '../../../../components/CardRow/CardRow';
import CardRowCard from '../../../../components/CardRow/CardRowCard';
import { useGetBestAskHistoryPrices } from '../../hooks/api/publicBestAskHistoryPricesApi';
import { useIntl } from 'react-intl';

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 1px;
  background-color: white !important;
`;

const StyledCardRow = styled(CardRow)`
`;

const StyledCardRowCard = styled(CardRowCard)`
  overflow-x: auto;
  overflow-y: auto;
  max-height: 70vh
`;

const StyledTd = styled.td`
  text-align: center;
`;

const TableHeader = styled.thead`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: white !important;
`;

const FirstRowTh = styled.th`
  border: 1px solid;
  background-color: white !important;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
`;

const SecondRowTh = styled.th`
  border: 1px solid;
  background-color: white !important;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
`;

const ThirdRowTh = styled.th`
  border: 1px solid;
  background-color: white !important;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
`;

const FixedFirstTd = styled.td`
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 0px;
  background-color: white !important;
`;

const FixedSecondTd = styled.td`
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  left: 100px;
  background-color: white !important;
`;

const FixedFirstTh = styled.th`
  border: 1px solid;
  position: -webkit-sticky;
  position: sticky;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 0px;
  top: 0px;
  background-color: white !important;
`;

const FixedSecondTh = styled.th`
  border: 1px solid;
  position: -webkit-sticky;
  position: sticky;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  left: 100px;
  top: 0px;
  background-color: white !important;
`;

const createProductRowYear = (product, map, productYears) => {
  const year = product.label?.substring(product?.label?.length -2);
  if (!productYears.includes(year)) {
    productYears.push(year);
  }
  map.set(year, product);
};

const createProductRowQuarter = (product, map, productQuarters) => {
  const quarter = product.label;
  if (!productQuarters.includes(quarter)) {
    productQuarters.push(quarter);
  }
  map.set(quarter, product);
};

const createProductRowMonth = (product, map, productMonths) => {
  const month = product.label;
  if (!productMonths.includes(month)) {
    productMonths.push(month);
  }
  map.set(month, product);
};

const BestAskPrices = ({ commodity }) => {
  // API handlers
  const { getAll: getBestAskHistoryPrices, data: bestAskPricesData } = useGetBestAskHistoryPrices();

  const [productYears, setProductYears] = useState([]);
  const [productQuarters, setProductQuarters] = useState([]);
  const [productMonth, setProductMonth] = useState([]);
  const [productsPrices, setProductPrices] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    (async () => {
      await getBestAskHistoryPrices(commodity);
    })();
  }, [getBestAskHistoryPrices, commodity]);

  useEffect(() => {
    if (bestAskPricesData) {
      const productYears = [];
      const productQuarters = [];
      const productMonths = [];
      const rows = [];
      bestAskPricesData.map((item) => {
        const yearProducts = new Map();
        const quarterProducts = new Map();
        const monthProducts = new Map();

        item.annualProductPrices.map((product) => {
          return createProductRowYear(product, yearProducts, productYears);
        });

        item.quarterProductPrices.map((product) => {
          return createProductRowQuarter(product, quarterProducts, productQuarters);
        });

        item.monthProductPrices.map((product) => {
          return createProductRowMonth(product, monthProducts, productMonths);
        });

        return rows.push({
          createdAt: item.createdAt,
          yearProductsPrices: yearProducts,
          quarterProductsPrices: quarterProducts,
          monthProductsPrices: monthProducts,
        });
      });

      setProductYears(productYears);
      setProductQuarters(productQuarters);
      setProductMonth(productMonths);

      setProductPrices(rows);
    }
  }, [bestAskPricesData]);


  return (
    <StyledCardRow>
      <StyledCardRowCard>
        <StyledTable>
          <TableHeader>
            <tr>
              <FixedFirstTh rowSpan='3'>{intl.formatMessage( { id: 'app.day' } )}</FixedFirstTh>
              <FixedSecondTh rowSpan='3'>{intl.formatMessage( { id: 'app.hour' } )}</FixedSecondTh>
              <FirstRowTh colSpan='6'>{intl.formatMessage( { id: 'app.year' } )}</FirstRowTh>
              <FirstRowTh colSpan='6'>{intl.formatMessage( { id: 'app.quarter' } )}</FirstRowTh>
              <FirstRowTh colSpan='6'>{intl.formatMessage( { id: 'app.month' } )}</FirstRowTh>
            </tr>
            <tr>
              {
                productYears.map((year) => (
                  <>
                    <SecondRowTh key={year}>{year}</SecondRowTh>
                    <SecondRowTh key={year}>{year}</SecondRowTh>
                  </>
                ))
              }
              {
                productQuarters.map((quarter) => (
                  <>
                    <SecondRowTh key={quarter}>{quarter}</SecondRowTh>
                    <SecondRowTh key={quarter}>{quarter}</SecondRowTh>
                  </>
                ))
              }
              {
                productMonth.map((month) => (
                  <>
                    <SecondRowTh key={month}>{month}</SecondRowTh>
                    <SecondRowTh key={month}>{month}</SecondRowTh>
                  </>
                ))
              }
            </tr>
            <tr>
              {
                [1, 2, 3].map(() => (
                  productYears.map(() => (
                    <>
                      <ThirdRowTh>{intl.formatMessage( { id: 'app.eur' } )}</ThirdRowTh>
                      <ThirdRowTh>{intl.formatMessage( { id: 'app.czk' } )}</ThirdRowTh>
                    </>
                  ))
                ))
              }
            </tr>
          </TableHeader>
          <tbody>
            {
              productsPrices?.map((item, key) => (
                <tr key={key}>
                  <FixedFirstTd>{format(new Date(item.createdAt), 'dd.MM.yyyy')}</FixedFirstTd>
                  <FixedSecondTd>{format(addMinutes(new Date(item.createdAt), new Date(item.createdAt).getTimezoneOffset()), 'HH')}</FixedSecondTd>
                  {
                    productYears.map((year) => (
                      <>
                        <StyledTd>{item.yearProductsPrices.get(year) &&
                          item.yearProductsPrices.get(year)?.valueEur != null ? item.yearProductsPrices.get(year)?.valueEur : 'N/A'}</StyledTd>
                        <StyledTd>{item.yearProductsPrices.get(year) &&
                          item.yearProductsPrices.get(year)?.valueCze != null ? item.yearProductsPrices.get(year)?.valueCze : 'N/A'}</StyledTd>
                      </>
                    ))
                  }
                  {
                    productQuarters.map((quarter) => (
                      <>
                        <StyledTd>{item.quarterProductsPrices.get(quarter) &&
                          item.quarterProductsPrices.get(quarter)?.valueEur != null ? item.quarterProductsPrices.get(quarter)?.valueEur : 'N/A'}</StyledTd>
                        <StyledTd>{item.quarterProductsPrices.get(quarter) &&
                          item.quarterProductsPrices.get(quarter)?.valueCze != null ? item.quarterProductsPrices.get(quarter)?.valueCze : 'N/A'}</StyledTd>
                      </>
                    ))
                  }
                  {
                    productMonth.map((month) => (
                      <>
                        <StyledTd>{item.monthProductsPrices.get(month) &&
                          item.monthProductsPrices.get(month)?.valueEur != null ? item.monthProductsPrices.get(month)?.valueEur : 'N/A'}</StyledTd>
                        <StyledTd>{item.monthProductsPrices.get(month) &&
                          item.monthProductsPrices.get(month)?.valueCze != null ? item.monthProductsPrices.get(month)?.valueCze : 'N/A'}</StyledTd>
                      </>
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </StyledTable>
      </StyledCardRowCard>
    </StyledCardRow>
  );
};

BestAskPrices.propTypes = {
  commodity: PropTypes.string,
};

export default BestAskPrices;
