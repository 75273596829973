import useApiHook from '../../../../hooks/api/useApiHook';
import { useCallback } from 'react';

export const useGetUser = () => {
  const { get, data, error, loading } = useApiHook(true);

  const getUser = useCallback(
    async (id) => {
      const url = `/users/${id}`;
      await get(url);
    },
    [get],
  );

  return { getUser, data, error, loading };
};

export const usePutUser = () => {
  const { put, data, error, loading } = useApiHook(true);

  const putUser = useCallback(
    async (id, body) => {
      const url = `/users/${id}`;
      await put(url, body);
    },
    [put],
  );

  return { putUser, data, error, loading };
};

export const useChangeUserLanguage = () => {
  const { put, data, error, loading } = useApiHook(true);

  const changeUserLanguage = useCallback(
    async (id, languageCode) => {
      const url = `/users/${id}/language/${languageCode}`;
      await put(url);
    },
    [put],
  );

  return { changeUserLanguage, data, error, loading };
};

export const usePostUser = () => {
  const { post, data, error, loading } = useApiHook(true);

  const postUser = useCallback(
    async (body) => {
      const url = `/users`;
      await post(url, body);
    },
    [post],
  );

  return { postUser, data, error, loading };
};

export const useSetUserState = () => {
  const { post, data, error, loading } = useApiHook(true);

  const postUserState = useCallback(
    async (id, action) => {
      const url = `/users/${id}/${action}`;
      await post(url);
    },
    [post],
  );

  return { postUserState, data, error, loading };
};

export const useGetUsersEnum = () => {
  const { get, data, error, loading } = useApiHook(true);

  const getUsersEnum= useCallback(async () => {
    const url = `/enums/users`;
    await get(url);
  }, [get]);

  return { getUsersEnum, data, error, loading };
};
