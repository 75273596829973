import { useCallback } from 'react';
import useApiHook from '../../../../hooks/api/useApiHook';

export const useGetNotifications = () => {
  const { get, data, error, loading } = useApiHook(true);
  const getNotifications = useCallback(async () => {
    const url = `/notifications`;
    await get(url);
  }, [get]);

  return { getNotifications, data, error, loading };
};
