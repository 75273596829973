import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import AppTitle from '../../MainLayout/Header/AppTitle';
import Container from '@material-ui/core/Container';
import useAppContext from '../../../../hooks/context/useAppContext';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
}));

const PublicTopBar = () => {
  const classes = useStyles({});
  const { breadCrumbs } = useAppContext();
  return (
    <AppBar position="static">
      <Toolbar>
        <AppTitle />
      </Toolbar>
      <Toolbar className={classes.toolbar}>
        <Container maxWidth="xl">{breadCrumbs || ''}</Container>
      </Toolbar>
    </AppBar>
  );
};

PublicTopBar.propTypes = {};

export default PublicTopBar;
