import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './routes';
import userRoles from '../constants/userRoles';
import LoadingIndicator from '../components/LoadingIndicator/LoadingIndicator';
import Layout from '../components/Layout/Layout';
import layouts from '../constants/layouts';
import useAppContext from '../hooks/context/useAppContext';

const MainRouter = () => {
  const { getUserRole, getCustomerIds } = useAppContext();
  let role = getUserRole();
  const customerIds = getCustomerIds();
  if (customerIds?.length > 1 && role === userRoles.CUSTOMER) {
    role = userRoles.CUSTOMER_MULTIPLE_COTRACTS;
  }
  if (customerIds?.length > 1 && role === userRoles.CUSTOMER_VIEWER) {
    role = userRoles.CUSTOMER_VIEWER_MULTIPLE_COTRACTS;
  }
  const activeRoutes = [...(routes[role] ? routes[role] : [])];

  return (
    <Switch>
      {activeRoutes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Layout type={route.layout ? route.layout : layouts.MAIN}>
                <Suspense fallback={<LoadingIndicator />}>
                  <route.component {...props} />
                </Suspense>
              </Layout>
            )}
          />
        );
      })}
    </Switch>
  );
};

export default MainRouter;
