import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Footer from '../Footer/Footer';
import PublicTopBar from './Header/PublicTopBar';
import styled from 'styled-components';

const ScrollableBody = styled.div`
  flex: 1;
  overflow: auto;
`;

export const SCROLLABLE_BODY = 'scrollableBody';
export const FIXED_FOOTER_DIV_ID = 'fixedFooter';
export const SNACKBAR_DIV = 'snackbar';

const PublicLayout = ({ children }) => {
  return (
    <>
      <ScrollableBody id={SCROLLABLE_BODY}>
        <PublicTopBar />
        <Container maxWidth="xl">
          {children}
        </Container>
        <Footer />
      </ScrollableBody>
      <div id={FIXED_FOOTER_DIV_ID} />
      <div id={SNACKBAR_DIV} />
    </>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.node,
};

export default PublicLayout;
