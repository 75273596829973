import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useGetNotifications, useDeleteNotifications, getUrl } from './notificationsApi';
import { format } from 'date-fns';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import useAppContext from '../../../hooks/context/useAppContext';
import { useHistory, useLocation } from 'react-router-dom';

const NotificationsList = styled.div`
  width: 300px;
  margin: 5px 0;
  a {
    text-decoration: none;
  }
`;

const NotificationsContent = styled.div`
  margin: 15px;
`;

const NotificationMenuContent = () => {
  const { getNotifications, data } = useGetNotifications();
  const { setNotificationCount } = useAppContext();

  useEffect(() => {
    if (data) {
      setNotificationCount(data.length);
    }
  }, [setNotificationCount, data]);

  useEffect(() => {
    (async () => {
      await getNotifications();
    })();
  }, [getNotifications]);

  return (
    <NotificationsList>
      <NotificationsContent>
        { data &&
          <Notification
            data={data}
          />
        }
      </NotificationsContent>
    </NotificationsList>
  );
};

NotificationMenuContent.propTypes = {
  handleClose: PropTypes.func,
  setCount: PropTypes.func,
};

export default NotificationMenuContent;

const NotificationsWrapper = styled.div`
  margin-bottom: 20px;
  padding: 5px;
  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
  }
`;

const NotificationsSectionTitle = styled.div`
  margin-bottom: 6px;
  font-size: 15px;
`;

const NotificationsSectionBody = styled.div`
  margin-bottom: 6px;
  font-size: 12px;
  opacity: 0.7;
`;

const StyledAnchor = styled.a`
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
    color: #000;
`;

const StyledIcon = styled(NotificationsActiveIcon)`
    color: #B00020;
    margin-right: 10px;
    font-size: 15px;
`;

const SectionWrapper = styled.div`
    display: table-cell;
`;

const Notification = ({ data }) => {
  const history = useHistory();
  const location = useLocation();
  const formattedDate = ( date ) => {
    return format(new Date(date), ' HH:mm - dd. MM. yyyy');
  };
  const { deleteNotifications, data: dataDel } = useDeleteNotifications();

  useEffect(() => {
    if (dataDel && dataDel?.notificationType?.id !== 2) {
      const url = getUrl(dataDel);
      if ( url !== location.pathname) {
        history.push(url);
      }
    }
  }, [history, dataDel, location]);

  return (
    <>
      {
        data.map((item) =>
          <NotificationsWrapper
            key={item.id}
            onClick={() => {
              deleteNotifications(item.id);
            }}
          >
            <SectionWrapper>
              <StyledIcon />
            </SectionWrapper>
            { (item.notificationType?.id !== 2 ) ?
              <SectionWrapper>
                <NotificationsSectionTitle>{item?.title}</NotificationsSectionTitle>
                <NotificationsSectionBody>{formattedDate(item.date)}</NotificationsSectionBody>
                <NotificationsSectionBody>{item?.description}</NotificationsSectionBody>
              </SectionWrapper> :
              <SectionWrapper>
                <StyledAnchor
                  href={getUrl(item)}
                  target="_blank"
                >
                  <NotificationsSectionTitle>{item?.title}</NotificationsSectionTitle>
                  <NotificationsSectionBody>{formattedDate(item.date)}</NotificationsSectionBody>
                  <NotificationsSectionBody>{item?.description}</NotificationsSectionBody>
                </StyledAnchor>
              </SectionWrapper>
            }
          </NotificationsWrapper>,
        )
      }
    </>
  );
};


Notification.propTypes = {
  data: PropTypes.any,
  handler: PropTypes.any,
  setId: PropTypes.any,
};
