import { CADMIUM_GREEN, GRAPH_GREY_FONT_COLOR, OLD_LAVENDER, WHITE, YELLOW_GREEN, CHECKBOX_CHECKED_MAIN } from '../constants/colors';

const GRAPH_BASE_FONT_SIZE = 12;
const GRAPH_BASE_FONT_COLOR = GRAPH_GREY_FONT_COLOR;
const GRAPH_BASE_FONT_FAMILY = 'Nimbus, Arial';

export const theme = {
  primaryColor: CADMIUM_GREEN,
  secondaryColor: OLD_LAVENDER,
  title: { primaryColor: YELLOW_GREEN, secondaryColor: WHITE },
  loader: { primaryColor: YELLOW_GREEN, secondaryColor: CADMIUM_GREEN },
  datatable: { body: { rowHeight: 32 } },
  sizes: {
    medium: '1350px',
  },
  graph: {
    baseFontSize: GRAPH_BASE_FONT_SIZE,
    baseFontColor: GRAPH_BASE_FONT_COLOR,
    baseFontFamily: GRAPH_BASE_FONT_FAMILY,
    baseFontStyle: `font-size: ${GRAPH_BASE_FONT_SIZE}px;
      color: ${GRAPH_BASE_FONT_COLOR};
      font-family: ${GRAPH_BASE_FONT_FAMILY};`,
    indexLabelSize: 9,
  },
  buttons: {
    backGroundColor: {
      primaryColor: CADMIUM_GREEN,
      secondaryColor: WHITE,
    },
    color: { primaryColor: WHITE, secondaryColor: CADMIUM_GREEN },
  },
  checkboxes: {
    color: {
      primaryColor: CHECKBOX_CHECKED_MAIN,
    },
  },
};

export const CORNER_RADIUS = 4;
