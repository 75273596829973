import React from 'react';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import useBreadCrumbs from '../../hooks/breadCrumbs/useBreadCrumbs';
import ProductPrices from './components/ProductPrices/ProductPrices';
import { FormattedMessage, useIntl } from 'react-intl';

const PublicProductPricesPage = () => {
  const intl = useIntl();

  useBreadCrumbs(intl.formatMessage( { id: 'app.publicProductPrices.sectionTitle' } ));
  return (
    <>
      <SectionHeader><FormattedMessage id={'app.publicProductPrices.sectionTitle'} /></SectionHeader>
      <ProductPrices />
    </>
  );
};

export default PublicProductPricesPage;
