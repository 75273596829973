import { useCallback } from 'react';
import useApiHook from '../../../../hooks/api/useApiHook';

export const useEnums= () => {
  const { get, data, error, loading } = useApiHook(true);

  const getEnums = useCallback(async () => {
    await get('/enums');
  }, [get]);

  return { getEnums, data, error, loading };
};
