import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {
  DatePicker as MaterialUiPicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { API_DATE_FORMAT_FULL } from '../../../constants/date';

const TimeRangeWrapper = styled.div`
  padding: 20px;
`;
const ButtonsWrapper = styled.div`
  padding: 0 20px 20px;
`;

const DatePicker = ({
  from,
  to,
  onSave,
  onOpen,
}) => {
  const intl = useIntl();
  const dateFormat = intl.messages['app.dateFormat'];

  const [open, setOpen] = useState(false);
  const [temporaryFrom, setTemporaryFrom] = useState(from);
  const [temporaryTo, setTemporaryTo] = useState(to);
  const [anchorEl, setAnchorEl] = useState(null);

  const id = open ? 'popper-time-frame' : undefined;

  const cancel = () => {
    setOpen(false);
    setTemporaryFrom(from);
    setTemporaryTo(to);
  };

  const save = () => {
    setOpen(false);
    onSave({
      from: temporaryFrom,
      to: temporaryTo,
    });
  };

  const toggle = (event) => {
    if (open) {
      cancel();
      setAnchorEl(null);
      return;
    }

    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    onOpen(open);
  }, [open, onOpen]);

  return (
    <>
      <Button
        onClick={toggle}
        variant="outlined"
        startIcon={<CalendarTodayIcon />}
        aria-describedby={id}
      >
        <FormattedDate
          value={from}
          year="numeric"
          month="2-digit"
          day="2-digit"
        /> - <FormattedDate
          value={to}
          year="numeric"
          month="2-digit"
          day="2-digit"
        />
      </Button>
      <Popper
        id={id}
        anchorEl={anchorEl}
        open={open}
        placement="bottom-end"
        transition
        modifiers={{
          flip: {
            enabled: true,
          },
          hide: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
      >
        <Paper
          elevation={3}
        >
          <TimeRangeWrapper>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={3}>
                <Grid item md>
                  <MaterialUiPicker
                    inputVariant="outlined"
                    variant="inline"
                    label="From"
                    format={dateFormat}
                    autoOk
                    disableFuture
                    disableToolbar
                    value={temporaryFrom}
                    onChange={(date) => setTemporaryFrom(format(date, API_DATE_FORMAT_FULL))}
                  />
                </Grid>
                <Grid item md>
                  <MaterialUiPicker
                    inputVariant="outlined"
                    variant="inline"
                    label="To"
                    format={dateFormat}
                    autoOk
                    disableFuture
                    disableToolbar
                    value={temporaryTo}
                    onChange={(date) => setTemporaryTo(format(date, API_DATE_FORMAT_FULL))}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </TimeRangeWrapper>
          <ButtonsWrapper>
            <Grid
              container
              justify="flex-end"
              spacing={1}
            >
              <Grid item>
                <Button
                  onClick={() => cancel()}
                >
                  <FormattedMessage id={'app.cancel'} />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => save()}
                >
                  <FormattedMessage id={'app.save'} />
                </Button>
              </Grid>
            </Grid>
          </ButtonsWrapper>
        </Paper>
      </Popper>
    </>
  );
};

DatePicker.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
};

export default DatePicker;
