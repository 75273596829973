import en from './en.json';
import cs from './cs.json';

export const getSupportLanguage = () => {
  const browserLanguage = navigator.language.slice(0, 2);
  return messages[browserLanguage] ? browserLanguage : 'cs';
};

export const messages = {
  en,
  cs,
};

export const isLanguageSupported = (languageCode) => {
  return !!messages[languageCode];
};

export const supportedLanguages = [
  {
    languageCode: 'cs',
    flagCode: 'cz',
    title: 'Česky',
    code: 'CZ',
  },
  {
    languageCode: 'en',
    flagCode: 'gb',
    title: 'English',
    code: 'EN',
  },
];
